import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CONTENT_TYPE } from '../constants/constants';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { S3 } from './s3';
import { InfoModalService } from '../shared/services/modals/info-modal.service';
import { MessageService } from '../shared/message.service';


@Injectable({
	providedIn: 'root'
})
export class Generic {

	constructor(
		private s3:S3,
		private infoModalService: InfoModalService,
		private message: MessageService
		) { }


	saveAsExcelFile(buffer: any, name: any): void {
		try {
			const data: Blob = new Blob([buffer], {
				type: CONTENT_TYPE.EXCEL
			});
			FileSaver.saveAs(data, name);
		} catch (e) {
			console.error(e);
		}
	}

	createFileInS3(blob:any, oldKey = '', file='') {
		try {
			const date = new Date();
			const formatDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
			const key = oldKey !== '' ? oldKey : `${file}/layout/upload/${formatDate}/${file}Layout_${new Date().getTime()}.xlsx`;
			return this.s3.upload(environment.BUCKETS.files, key, blob, blob.type);
		} catch (e) {
			console.error(e);
			return e;
		}
	}

	async putFileToS3(blob: any, key: string, bucket: string) {
		try {
			return this.s3.upload(bucket, key, blob, blob.type);
		} catch (e) {
			console.error(e);
			return e;
		}

	}

	async readFileExcel(file:any) {
		const readFilePromise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.onload = (e) => {
				let arrayBuffer: any = fileReader.result;
				const data = new Uint8Array(arrayBuffer);
				const arr = new Array();
				for (let i = 0; i !== data.length; ++i) {
					arr[i] = String.fromCharCode(data[i]);
				}
				const bstr = arr.join('');
				const workbook = XLSX.read(bstr, { type: 'binary' });
				const first_sheet_name = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[first_sheet_name];

				resolve(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
			};
			fileReader.readAsArrayBuffer(file);
		});
		return await readFilePromise;
	}

	async downloadFile(layout: string) {
		try {
			const file = await this.s3.download(environment.BUCKETS.files, layout);
			const arrayKey: any = layout.split('/');
			this.saveAsExcelFile(file.Body, arrayKey[arrayKey.length - 1]);
		} catch (error) {
			this.infoModal(this.message.error, this.message.errorDownload, 'warning');
		}
	}

	async downloadFileFromBucket(bucket: string, key: string) {
		try {
			const file = await this.s3.download(bucket, key);
			const arrayKey: any = key.split('/');
			await  this.saveAsExcelFile(file.Body, arrayKey[arrayKey.length - 1]);
			return true;
		} catch (error) {
			// this.infoModal(this.message.error, this.message.errorDownload, 'warning');
			return false;
		}
	}

	infoModal(title: string, text: string, type: string) {
		this.infoModalService.openInfoModal({
		  title: title,
		  text: text,
		  type: type,
		  cancelButton: false,
		  confirmButton: true,
		});
	}

	getColorText (color: string): string {
		let f = color.substring(color.length-2)

		if (f === 'ff' && color.length > 7) {
			color = color.substring(0, color.length-2)
		}
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
		let rgb = result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
		let C = [ rgb?.r ? rgb.r/255 : 0, rgb?.g ? rgb.g/255 : 0, rgb?.b ? rgb.b/255 : 0];

		for ( var i = 0; i < C.length; ++i ) {
			if ( C[i] <= 0.03928 ) { C[i] = C[i] / 12.92 }
			else { C[i] = Math.pow( ( C[i] + 0.055 ) / 1.055, 2.4); }
		}
		let L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2];
		if ( L > 0.5 ) { return 'black';}
		else { return 'white'; }
	}

	static flat<T>(array: T[], key: string): T[] {
    let result: any[] = [];
    array.forEach((a: any) => {
      result.push(a);
      if (Array.isArray(a[key])) {
        result = result.concat(this.flat(a[key], key));
      }
    });
    return result;
	}

	static dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);
  
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
  
    // create a view into the buffer
    const ia = new Uint8Array(ab);
  
    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], {type: mimeString});
    return blob;
  
  }
}

