import {createReducer, on} from "@ngrx/store";
import * as project from './project.actions';
import { ConfigurationProject, ProjectConfiguration, ProjectInfo, StepProject } from "../../project-management/models/project.model";

export interface ProjectState {
	project: ProjectConfiguration;
	projectsForCreation: ProjectInfo[];
	configurationProject: Partial<ConfigurationProject>;
}

export const initialState: ProjectState = {
	project: {
		projectId: -1,
		projectName: '',
		clientId: -1,
		logo: '',
		allClient: false,
		color: '',
		backgroundUrl: '',
		logoProject: ''
	},
	projectsForCreation: [],
	configurationProject: {
		type: 1,
		step: StepProject.INFO,
		percentage: 33
	},
}

export const projectReducer = createReducer(
	initialState,
	on(project.setProject, (state, {project}) => ({...state, project: { ...state.project, ...project }})),
	on(project.setClient, (state, {clientId, logo}) => ({...state, project: {...state.project, clientId, logo, allClient: clientId === 0}})),
	// on(project.setLogo, (state, {logo}) => ({...state, project: { ...state.project, logo}})),
	on(project.loadProject, (state, {project}) => ({...state, project})),
	on(project.unsetProject, (state) => ({...state, ...initialState})),
	on(project.loadProjectsForCreation, (state, {projects}) => ({...state, projectsForCreation: projects})),
	on(project.updateProjectsForCreation, (state, {project}) => ({
		...state, 
		projectsForCreation: [
			...state.projectsForCreation.map(p => {
				if (p.index === project.index) return project;
				return p
			}),
		]}
	)),
	on(project.loadAdminDesign, (state, {key, value}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			admin: {
				...state.configurationProject.admin,
				[key]: value
			}
		}
	})),
	on(project.loadAllAdminAppDesign, (state, {admin, mobile}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			admin: admin,
			mobile: mobile
		}
	})),
	on(project.loadProjectInfo, (state, {project}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			info: project,
		}
	})),
	on(project.clearProjectInfo, (state) => ({
		...state, 
		configurationProject: initialState.configurationProject
	})),
	on(project.selectStepProject, (state, {step}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			step,
		}
	})),
	on(project.selectTypeDesign, (state, {value}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			type: value
		}
	})),
	on(project.loadAppDesign, (state, {key, value}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			mobile: {
				...state.configurationProject.mobile,
				[key]: value
			}
		}
	})),
	on(project.clearDesign, (state) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			mobile: {},
			admin: {}
		}
	})),
	on(project.setPercentage, (state, {percentage}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			percentage,
		}
	})),
)
