import { createReducer, on } from "@ngrx/store";
import * as action from "./auth.actions";
import {DesignProject, NewUser, OperationTypes} from "./models/login.model";

export interface AuthState {
	user: NewUser | null;
	operationType: OperationTypes | null;
	designProjects: DesignProject[]
}

export const initialState: AuthState = {
	user: null,
	operationType: null,
	designProjects: []
}

export const authReducer = createReducer(
	initialState,
	on(action.setUser, (state, {user}) => ({ ...state, user})),
	on(action.logOut, (state) => ({...state, ...initialState})),
	on(action.setOperationType, (state, {operation}) => ({ ...state, operationType: operation})),
	on(action.setDesingProjects, (state, {designs}) => ({ ...state, designProjects: designs})),
)
