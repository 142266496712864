import {createAction, props} from "@ngrx/store";
import {ProjectState} from "./project.reducer";
import { DesignAdmin, DesignMobile, ProjectConfiguration, ProjectData, ProjectInfo, StepProject } from "../../project-management/models/project.model";

export const setProject = createAction('[PROJECT] Set project', props<{project: ProjectData}>());
export const setClient = createAction('[PROJECT] Set Client', props<{clientId: number, logo: string}>());
// export const setLogo = createAction('[PROJECT] Set Logo', props<{logo: string}>());
export const loadProject = createAction('[PROJECT] Load Project', props<{project: ProjectConfiguration}>());
export const unsetProject = createAction('[PROJECT] Unset project');

export const loadProjectsForCreation = createAction('[PROJECT MASSIVE]: Load projects', props<{projects: ProjectInfo[]}>());
export const updateProjectsForCreation = createAction('[PROJECT MASSIVE]: Update projects', props<{project: ProjectInfo}>());

export const loadProjectInfo = createAction('[SINGLE PROJECT]: Load Project', props<{project: ProjectInfo}>());
export const selectTypeDesign = createAction('[SINGLE PROJECT]: Type Design Project', props<{value: number}>());
export const selectStepProject = createAction('[SINGLE PROJECT]: Step Project', props<{step: StepProject}>());
export const setPercentage = createAction('[SINGLE PROJECT]: Set percentage bar', props<{percentage: number}>());
export const loadAdminDesign = createAction('[SINGLE PROJECT]: Load Admin Design', props<{value: string, key: keyof DesignAdmin}>());
export const loadAllAdminAppDesign = createAction('[SINGLE PROJECT]: Load All Admin and App Design', props<{admin: DesignAdmin, mobile: DesignMobile}>());
export const clearProjectInfo = createAction('[SINGLE PROJECT]: clear Project');
export const loadAppDesign = createAction('[SINGLE PROJECT]: Load App Design', props<{value: string, key: keyof DesignMobile}>());
export const clearDesign = createAction('[SINGLE PROJECT]: Clear App and Admin Design');
