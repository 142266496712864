import { createSelector } from "@ngrx/store";
import { AppState } from "../store/app.reducer";
import { AuthState } from "./auth.reducer";

export const selectFeature = (state: AppState) => state.auth;

export const selectOperationType = createSelector(
  selectFeature,
  (state: AuthState) => state.operationType
)

export const selectUserId = createSelector(
  selectFeature,
  (state: AuthState) => state.user?.idUser
)

export const selectclientList = createSelector(
  selectFeature,
  (state: AuthState) => state.operationType?.businessList
)

export const selectDesignProjects = createSelector(
  selectFeature,
  (state: AuthState) => state.designProjects
)