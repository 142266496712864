import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { myRoutes, Route } from './routes';
import { USER } from '../shared/models/user';
import { AuthStorage } from '../auth/models/login.model';
import { ProjectState } from '../store/project/project.reducer';

@Injectable({
	providedIn: 'root',
})
export class UserProfileGuard implements CanActivate, CanActivateChild {
	constructor(private router: Router) {}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| boolean
		| UrlTree
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree> {
		return this.validate(state);
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.validate(state);
	}

	validate(state: RouterStateSnapshot): boolean | UrlTree {
		let auth: AuthStorage = localStorage.getItem('auth')
			? JSON.parse(atob(localStorage.getItem('auth') || ''))
			: null;
		let project: ProjectState = localStorage.getItem('project')
			? JSON.parse(atob(localStorage.getItem('project') || ''))
			: null;
		let user: USER = localStorage.getItem('user')
			? JSON.parse(atob(localStorage.getItem('user') || ''))
			: null;
		if (user && user.idUser) {
			user.idRol = 2;
			return this.validateRoutesOldLogin(user, state.url);
		}
		if (auth && auth.user && auth.operationType) {
			return this.validateRoutes(auth, state.url, project);
		}
		this.router.navigate(['/auth/login']);
		return false;
	}

	validateRoutesOldLogin(user: USER, url: string) {
		let routes: Route[];
		if (user.typeCustom === 1) {
			routes = myRoutes.adminCustom;
		} else if (user.idRol === 1) {
			routes = myRoutes.user;
		} else {
			routes = myRoutes.admin;
		}

		const routeFound = routes.find((element) => url.includes(element.route));

		return routeFound !== undefined;
	}

	validateRoutes(user: AuthStorage, url: string, project: ProjectState | null) {
		let routes: Route[];
		if (!project) {
			routes = myRoutes.adminNotProyect;
		} else if (user.operationType?.id === 2 || user.operationType?.id === 1) {
			let modules: string[] = JSON.parse(atob(localStorage.getItem('routes') || ''));
			return modules.some(item => url.includes(item)) 
		}  else {
			routes = myRoutes.user;
		}

		const routeFound = routes.find((element) => element.route && url.includes(element.route));

		return routeFound !== undefined;
	}
}
