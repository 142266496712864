import { createSelector } from "@ngrx/store";
import { AppState } from "../app.reducer";
import { ProjectState } from "./project.reducer";

export const selectFeature = (state: AppState) => state.project;

export const selectProjectConfiguration = createSelector(
  selectFeature,
  (state: ProjectState) => state.project
)

export const selectLogo = createSelector(
  selectFeature,
  (state: ProjectState) => state.project.logo
)

export const selectProjectsForCreation = createSelector(
  selectFeature,
  (state: ProjectState) => state.projectsForCreation
)

export const isInvalidProjectsForCreation = createSelector(
  selectFeature,
  (state: ProjectState) => state.projectsForCreation.length ? state.projectsForCreation.some(p => !p.valid) : true
)

export const isValidProject = createSelector(
  selectFeature,
  (state: ProjectState) => state.configurationProject.info ? state.configurationProject.info.valid : false
)

export const selectConfigurationProject = createSelector(
  selectFeature,
  (state: ProjectState) => state.configurationProject
)

export const isValidAppDesign = createSelector(
  selectFeature,
  (state: ProjectState) => state.configurationProject.mobile 
    ? !!(state.configurationProject.mobile.color && state.configurationProject.mobile.secuColor && state.configurationProject.mobile.urlLogoHome && state.configurationProject.mobile.urlLogoLogin && state.configurationProject.mobile.urlLogoSplash)
    : false
)

export const isValidAdminDesign = createSelector(
  selectFeature,
  (state: ProjectState) => state.configurationProject.admin 
    ? !!(state.configurationProject.admin.color && state.configurationProject.admin.background && state.configurationProject.admin.urlLogo)
    : false
)
